.servicesContainer {
    padding: 40px;
    background: rgba(34, 56, 91, 1);
}

.sectionTitle {
    color: white;
    font-size: 32px;
    padding: 0 0 16px 16px;
    font-family: "Montserrat, sans-serif";
}

.services{
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media all and (min-width: 900px){
    .services{
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: unset;
    }
}