.valuesContainer {
    padding: 40px;
    background: rgba(34, 56, 91, 1);
}

.sectionTitle {
    color: white;
    font-size: 32px;
    padding: 0 0 16px 16px;
    font-family: "Montserrat, sans-serif";
}

.values{
    display: flex;
    flex-direction: column;
}

@media all and (min-width: 900px){
    .values{
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.valuesCard{
    background-color: transparent !important;
    border-radius: 0 !important;
}

.valuesCard .title{
    color: rgba(179, 168, 127, 1);
    text-align: center;
    margin: 24px auto;
}

.valuesCard .description{
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    margin-bottom: 24px;
}