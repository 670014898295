.cover{
    min-height: 100vh;
    display: flex;
    font-size: calc(10px + 2vmin);
    position: relative;
}

.coverText {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 40px;
    font-family: 'Montserrat, sans-serif';
    position: absolute;
    z-index: 1;
    min-height: 100vh;
}

.coverIntro h1 {
    color: rgba(179, 168, 127, 1);
    margin-top: 80px;
}

.coverIntro p {
    font-size: 16px;
    background-color: rgba(255, 255, 255, 0.6);
    color: rgba(34, 56, 91, 1);
    border-radius: 5px;
    padding: 8px;
    text-align: center;
}

/* @media all and (min-width:500px) {
    .coverIntro h1{
        margin-top: 60px;
    }
} */

@media all and (min-width:900px) {
    .coverIntro h1{
        margin-top: 70px;
    }

    .coverIntro p{
        font-size: 20px;
        background-color: transparent;
        color: white;
        border-radius: 0;
        text-align: start;
    }
}

.coverIntro h4 {
    position: relative;
    padding-left: 32px;
}

.coverIntro h4::after {
    content: "";
    display: block;
    width: 24px;
    height: 2px;
    background: white;
    left: 0;
    top: 50%;
    position: absolute;
}

.coverImage {
    position: absolute;
    width: 100%;
    background: linear-gradient(to left, transparent, rgba(34, 56, 91, 1)),
        url('../assets/cover.jpeg');
    min-height: 100vh;
}

@media all and (min-width:900px) {
    .cover {
        
    }

    .coverText{
        width: 45%;
        background-color: rgba(34, 56, 91, 1);
        position: relative;
    }

    .coverImage {
        width: 55%;
        position: relative;
    }
}