footer {
    background-color: rgba(34, 56, 91, 1);
    padding: 0 50px;
}

.container {
    max-width: 100%;
    width: 100%;
    padding: 30px 50px;
    margin-right: auto;
    margin-left: auto;
}

.footerContent {}

.footerRow {
    display: flex;
    flex-direction: column;
}

@media all and (min-width: 900px) {
    .footerRow{
        flex-direction: row;
    }
}

.footerColumn {
    margin-bottom: 24px;
}

@media all and (min-width: 900px) {
    .footerColumn{
        flex: 0 0 25%;
        max-width: 25%;   
    }
}

.footerColumn nav {
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    outline: none;
    margin: 0;
    padding: 0;
}

.footerColumn nav ul {
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    outline: none;
    margin: 0;
    padding: 0;
}

.footerColumn nav ul li {
    display: list-item;
    text-align: -webkit-match-parent;
    margin-top: 8px;
}

.footerColumn nav ul>li>a,
.footerColumn nav ul>li>p {
    color: rgba(194, 194, 194, 0.6);
    margin-bottom: 0;
    font-size: 11px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1.6;
    font-variant-numeric: proportional-nums;
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
}

.footerTitle {
    position: relative;
    color: rgba(255, 255, 255, 1);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
    padding-bottom: 10px;
    letter-spacing: 2px;
}

.footer-title::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 15px;
    height: 2px;
    background-color: rgba(255, 255, 255, 1);
}