

.flexContainer {
    margin-top: 100px;
    padding: 40px;
    background: rgba(34, 56, 91, 1);
    display: flex;
    flex-wrap: wrap;
}
@media all and (min-width:900px){
    .flexContainer{
        margin-top: 156px;
        flex-wrap: nowrap;
    }
}

.sectionTitle {
    color: white;
    font-size: 32px;
    padding: 0 0 16px 16px;
    font-family: "Montserrat, sans-serif";
}

.text{
    font-size: 16px;
    font-family: "Montserrat, sans-serif";
    background-color: rgba(255, 255, 255, 0.6);
    color: rgba(34, 56, 91, 1);
    border-radius: 5px;
    padding: 16px;
    margin: 0 16px;
}

.aboutContainer{
    flex: 1 0 50%;
}

.missionVision{
    display: flex;
    flex-direction: column;
}
.mission{
    margin-top: 24px;
}
.vision{
    margin-top: 24px;
}
@media all and (min-width:900px) {
    .mission{
        margin-top: 0;
    }
}


/***************************/

/* .gridContainer{
    display: grid;
    grid-template-columns: 300px 300px;
    grid-template-rows: auto;
    margin-top: 156px;
    background-color: rgba(255, 0, 0, 0.6);
}

.about{
    grid-area: about;
    background-color: aliceblue;
}
.image{
    grid-area: image;
    background-color: antiquewhite;
}
.image img{
    width: 200px;
}
.mission{
    grid-area: mission;
    background-color: aqua;
}
.vision{
    grid-area: vision;
    background-color: aquamarine;
} */
