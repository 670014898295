header {
    background-color: rgba(34, 56, 91, 1) !important;
    }
        /* header.MuiPaper-elevation0{
        } */
        header.MuiPaper-elevation4 {
            background-color: rgba(34, 56, 91, 1) !important;
        }
    
        header button {
    font-family: 'Montserrat, sans-serif' !important;
    font-weight: bold;
}

@media (min-width: 900px) {
    header{
        background-color: transparent !important;
    }

    header.MuiPaper-elevation4 {
        /* background-color: white !important; */
        background-color: rgba(34, 56, 91, 1) !important;
    }
}

header .container {
    padding: 16px 24px;
}

.logo {
    height: 16vmin;
    pointer-events: none;
}

.menuItemLink {
    font-family: 'Montserrat, sans-serif' !important;
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
    color: rgba(34, 56, 91, 1);
}

.navLink{
    margin: 16px; 
    color: white;
    display: block;
    font-family: 'Montserrat, sans-serif' !important;
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
}