.projectsContainer {
    padding: 40px;
    background: rgba(34, 56, 91, 1);
}

.sectionTitle {
    color: white;
    font-size: 32px;
    padding: 0 0 16px 16px;
    font-family: "Montserrat, sans-serif";
}

.slick-slide img {
    margin: auto;
}

.slider {
    height: 450px;
}

.slider .slick-list {
    max-height: 100%;
}

.slider .slide {
    position: relative;
    /* width: 300px; */
    height: 450px;
    margin-left: 16px;
    margin-right: 16px;
    background-size: cover;
    background-repeat: 'no-repeat';
    background-position: 'center';
    display: flex;
    justify-content: center;

    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

.slider .slide:hover {
    filter: brightness(60%);
    -webkit-filter: brightness(60%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

/* .slider .slide img {
    width: 100%;
    max-width: 100%;
} */

.slider .slide .slideText {
    position: absolute;
    color: rgba(255, 255, 255, 1);
    /* z-index: 100; */
    bottom: 50px;
    text-align: center;
}

.slider .slide .slideText h3{
    font-size: 24px;
    margin-bottom: 8px;
}

.slider .slide .slideText p{
    font-size: 16px;
    margin: 0 auto;
}